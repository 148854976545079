export const faqs = [
  {
    id: 1,
    question: "01. Discussion",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit quam lectus, facilisis auctor, ultrices ut, elementum vulputate, nunc.",
  },
  {
    id: 2,
    question: "02. Design",
    answer:
      "Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet dignissim pellentesque felis.",
  },
  {
    id: 3,
    question: "03. Development",
    answer:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec urna.",
  },
  {
    id: 4,
    question: "04. Production",
    answer:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec urna.",
  },
];

export const homepagefaqs = [
  {
    id: 1,
    question: "01. What services does GetBiz Solutions offer?",
    answer:
      "We offer tailored business solutions, including automation, security, cloud, and mobile services",
  },
  {
    id: 2,
    question: "02. How do I get started with GetBiz Solutions?",
    answer:
      "Contact us today for a free consultation, and we'll help you identify the right solutions for your business.",
  },
  {
    id: 3,
    question: "03. Why choose GetBiz Solutions over others?",
    answer:
      "We offer customized solutions, expert support, and a commitment to your business’s success.",
  },
  {
    id: 4,
    question: "04. Is GetBiz Solutions suitable for all businesses?",
    answer:
      "Yes, our solutions are scalable and can be tailored to fit businesses of all sizes.",
  },
];

export const itsolutionspagefaqs = [
  {
    id: 1,
    question: "01. What industries do your IT solutions cater to?",
    answer:
      "We serve diverse industries including healthcare, finance, and retail.",
  },
  {
    id: 2,
    question: "02. How do your solutions improve ROI?",
    answer:
      "Our solutions streamline processes and reduce costs, leading to higher returns.",
  },
  {
    id: 3,
    question: "03. Are your IT solutions scalable?",
    answer:
      " Yes, our solutions are designed to grow with your business needs.",
  },
  {
    id: 4,
    question: "04. What security measures are included?",
    answer:
      " We implement robust security protocols to protect your data and assets.",
  },
];

export const customerservicesolutionspagefaqs = [
  {
    id: 1,
    question: "01. What is GetBiz Solutions customer service platform?",
    answer:
      "An integrated solution to manage tickets, chats, and calls for faster customer support.",
  },
  {
    id: 2,
    question: "02. Can I customize workflows with GetBiz Solutions?",
    answer:
      "Yes, workflows can be customized to fit your specific business processes.",
  },
  {
    id: 3,
    question: "03. Does it support multi-channel communication?",
    answer:
      "Yes, it supports chats, emails, calls, and social media channels in one dashboard.",
  },
  {
    id: 4,
    question: "04. Does the system provide analytics?",
    answer:
      "Yes, real-time analytics help you track performance and customer satisfaction.",
  },
];

export const crmsolutionspagefaqs = [
  {
    id: 1,
    question: "01. What is CRM and why do I need it?",
    answer:
      "CRM helps you manage customer relationships, track interactions, and streamline your business processes.",
  },
  {
    id: 2,
    question: "02. How does GetBiz Solutions CRM help with sales?",
    answer:
      "It tracks deals, automates follow-ups, and gives insights to help you close more deals efficiently.",
  },
  {
    id: 3,
    question: "03. Can I automate tasks with this CRM?",
    answer:
      "Yes, you can automate routine tasks like follow-ups, lead tracking, and ticket management.",
  },
  {
    id: 4,
    question: "04. Does the CRM offer marketing features?",
    answer:
      "Yes, you can design, execute, and track marketing campaigns within the CRM.",
  },
];

export const enterprisesolutionspagefaqs = [
  {
    id: 1,
    question: "01. What is ESM  in GetBiz Solutions?",
    answer:
      "GetBiz Solutions offers a comprehensive Enterprise Service Management (ESM) platform that streamlines service management across the entire organization.",
  },
  {
    id: 2,
    question: "02. Can I customize workflows in GetBiz Solutions ESM?",
    answer:
      "Yes, our platform allows full customization of workflows to meet your specific enterprise needs.",
  },
  {
    id: 3,
    question: "03. How does GetBiz Solutions ESM improve team collaboration?",
    answer:
      "It provides a unified platform where teams can work together on shared goals, making operations more efficient.",
  },
  {
    id: 4,
    question: "04. Does the platform support automation?",
    answer:
      "Yes, automation features in GetBiz Solutions ESM help automate repetitive tasks to boost efficiency.",
  },
];

export const salessolutionspagefaqs = [
  {
    id: 1,
    question: "01. What is sales automation?",
    answer:
      "Sales automation streamlines repetitive tasks, allowing your sales team to focus on closing deals rather than administrative work.",
  },
  {
    id: 2,
    question: "02. How does sales automation improve productivity?",
    answer:
      "It automates lead nurturing, follow-ups, and reporting, helping sales reps focus on high-value activities.",
  },
  {
    id: 3,
    question: "03. Can I integrate sales automation with my CRM?",
    answer:
      "Yes, GetBiz Solutions integrates with most major CRMs, enhancing your existing workflows.",
  },
  {
    id: 4,
    question: "04. What industries benefit from sales automation?",
    answer:
      "Sales automation is valuable across industries, from e-commerce to B2B services, providing efficiencies for any sales team.",
  },
];

export const casolutionspagefaqs = [
  {
    id: 1,
    question: "01. What is sales automation for CA firms?",
    answer:
      "Sales automation helps CA firms streamline workflows, manage client documents, automate billing, and ensure compliance through integrated solutions.",
  },
  {
    id: 2,
    question: "02. How does automation enhance efficiency in CA firms?",
    answer:
      "Automation reduces manual tasks, speeds up billing and reporting processes, and minimizes errors, leading to greater operational efficiency.",
  },
  {
    id: 3,
    question: "03. Can I automate billing and time tracking for my CA firm?",
    answer:
      "Yes, our system automates both billing and time tracking, making it easier to manage client invoices and track work hours.",
  },
  {
    id: 4,
    question: "04. How does compliance automation work?",
    answer:
      "Compliance automation includes automated reminders and alerts for important deadlines, ensuring that you meet all regulatory requirements.",
  },
];

export const cssolutionspagefaqs = [
  {
    id: 1,
    question: "01. What does automation offer for CS firms?",
    answer:
      "Automation improves efficiency in managing client cases and documents.",
  },
  {
    id: 2,
    question: "02. How does automation benefit CS firms?",
    answer:
      " Automation reduces manual work, speeds up processes, and ensures accuracy in billing and compliance, leading to better efficiency.",
  },
  {
    id: 3,
    question: "03. Can billing and invoicing be automated?",
    answer:
      "Yes, our system automates billing and invoicing, making it easier to manage and track client payments.",
  },
  {
    id: 4,
    question: "04. How are compliance alerts managed? ",
    answer:
      "The system provides automated compliance alerts and reminders for critical deadlines, helping you avoid potential issues.",
  },
];

export const lawsolutionspagefaqs = [
  {
    id: 1,
    question: "01. What does automation offer for CS firms?",
    answer:
      "Automation improves efficiency in managing client cases and documents.",
  },
  {
    id: 2,
    question: "02. How does automation benefit CS firms?",
    answer:
      " Automation reduces manual work, speeds up processes, and ensures accuracy in billing and compliance, leading to better efficiency.",
  },
  {
    id: 3,
    question: "03. Can billing and invoicing be automated?",
    answer:
      "Yes, our system automates billing and invoicing, making it easier to manage and track client payments.",
  },
  {
    id: 4,
    question: "04. How are compliance alerts managed? ",
    answer:
      "The system provides automated compliance alerts and reminders for critical deadlines, helping you avoid potential issues.",
  },
];

export const bpokposolutionspagefaqs = [
  {
    id: 1,
    question: "01. How does automation benefit BPO/KPO firms?",
    answer:
      "Automation improves customer service, task management, and reporting, making processes faster.",
  },
  {
    id: 2,
    question: "02. Can BPO/KPO firms automate customer service?",
    answer:
      " Yes, you can automate responses and track client interactions for efficient customer service.",
  },
  {
    id: 3,
    question: "03. How can task management be automated?",
    answer:
      "Automation tools assign tasks based on priority, track progress, and set deadlines for your team.",
  },
  {
    id: 4,
    question: "04. What is employee performance tracking automation?",
    answer:
      "Employee performance automation helps monitor productivity and identifies areas for improvement.",
  },
];

export const realestatesolutionspagefaqs = [
  {
    id: 1,
    question: "01. How does automation benefit real estate agencies?",
    answer:
      "Automation helps manage listings, lead tracking, contracts, and customer interactions efficiently.",
  },
  {
    id: 2,
    question: "02. Can property listings be automated?",
    answer:
      " Yes, automation allows you to manage and update property listings across multiple platforms easily.",
  },
  {
    id: 3,
    question: "03. How does contract automation work in real estate? ",
    answer:
      "Contract automation helps create, track, and manage property sale contracts without manual effort.",
  },
  {
    id: 4,
    question: "04. What is lead management automation for real estate?",
    answer:
      "Lead management automation helps track potential buyers, follow up, and manage interactions easily.",
  },
];

export const cpasolutionspagefaqs = [
  {
    id: 1,
    question: "01. What is sales automation for CPA firms? ",
    answer:
      "Sales automation involves automating client management, billing, compliance tasks, and reporting to enhance CPA firm efficiency.",
  },
  {
    id: 2,
    question: "02. How does automation improve a CPA firm’s efficiency?",
    answer:
      " Automation reduces manual tasks, speeds up processes, and minimizes errors, leading to greater operational efficiency.",
  },
  {
    id: 3,
    question: "03. Can the system handle billing and time tracking? ",
    answer:
      "Yes, our system automates both billing and time tracking, streamlining the process and reducing manual effort.",
  },
  {
    id: 4,
    question: "04. What is the role of compliance alerts in the system?",
    answer:
      "Compliance alerts are automated reminders that help you stay on top of important deadlines and regulatory requirements.",
  },
];

export const marketingsolutionspagefaqs = [
  {
    id: 1,
    question: "01. What is GetBiz Solutions marketing automation?",
    answer: "GetBiz Solutions provides automation tools to streamline your marketing efforts, enhance campaign management, and analyze results.",
  },
  {
    id: 2,
    question: "02. How can GetBiz Solutions boost marketing ROI?",
    answer: "By automating repetitive tasks and refining campaign strategies, GetBiz Solutions helps maximize your ROI with less manual effort.",
  },
  {
    id: 3,
    question: "03. Does GetBiz Solutions include CRM automation?",
    answer: "Yes, GetBiz Solutions provides a CRM integrated with our marketing automation tools for effective customer relationship management.",
  },
  {
    id: 4,
    question: "04. What support does GetBiz Solutions offer?",
    answer: "GetBiz Solutions provides around-the-clock support to ensure your marketing tools operate smoothly and address any issues promptly.",
  },
];

export const leaveapprovedeclinespagefaqs = [
  {
    id: 1,
    question: "01. What is the Easy Approval/Decline feature?",
    answer: " It’s a tool that simplifies the process of approving or declining leave requests, making it quick and efficient for managers.",
  },
  {
    id: 2,
    question: "02. How are leave requests submitted and reviewed?",
    answer: "Employees submit leave requests through the self-service portal, which are then reviewed by managers using a streamlined approval workflow.",
  },
  {
    id: 3,
    question: "03. Can managers see leave balances before approving requests?",
    answer: "Yes, managers can view leave balances and other relevant information before making a decision.",
  },
  {
    id: 4,
    question: "04. Are notifications sent after a request is approved or declined?",
    answer: "Absolutely. The system automatically notifies the employee of the decision, keeping everyone in the loop.",
  },
  {
    id: 5,
    question: "05. Does the system allow for multiple levels of approval?",
    answer: "Yes, the workflow can be configured to require approvals from multiple managers, depending on your organizational structure.",
  },
];

export const leavehistorypagefaqs = [
  {
    id: 1,
    question: "01. What information is available in the Leave History?",
    answer: "The Leave History feature records details such as leave dates, types, reasons, approval status, and more.",
  },
  {
    id: 2,
    question: "02. Can employees access their own leave history?",
    answer: "Yes, employees can view their complete leave history through the self-service portal.",
  },
  {
    id: 3,
    question: "03. How long is leave history data stored?",
    answer: "Leave history data is stored indefinitely, allowing you to access it whenever needed, depending on your organization's retention policy.",
  },
  {
    id: 4,
    question: "04. Can leave history be exported for reporting?",
    answer: "Absolutely. The data can be exported into various formats for reporting and analysis purposes.",
  },
  {
    id: 5,
    question: "05. How does Leave History help in compliance?",
    answer: "It provides a reliable record of all leave activities, helping your organization meet regulatory requirements and resolve any disputes.",
  },
];

export const leavetypespagefaqs = [
  {
    "id": 1,
    "question": "What types of leave can I manage with this system?",
    "answer": "You can manage multiple leave types, including vacation, sick leave, maternity/paternity leave, and custom leave categories."
  },
  {
    "id": 2,
    "question": "Can I create custom leave types?",
    "answer": "Yes, the system allows you to create custom leave types to meet the specific needs of your business."
  },
  {
    "id": 3,
    "question": "Can I set different approval workflows for different leave types?",
    "answer": "Yes, you can create unique approval workflows for each leave type, ensuring the right manager reviews each request."
  },
  {
    "id": 4,
    "question": "How are leave entitlements handled for different leave types?",
    "answer": "You can set custom entitlements and accrual rates for each type of leave, ensuring fairness and accuracy."
  }
];


export const faqDataMain = [
  {
    id: 1,
    question: "What do you mean by an End Product?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vehicula porttitor rutrum. Ut laoreet sodales rutrum. Ut eget tristique nibh. Cras mattis est eget lacus dignissim, non consectetur velit pellentesque. Ut sit amet placerat eros, id gravida arcu. Sed felis purus, tempor ac vestibulum nec, consectetur at purus.",
  },
  {
    id: 2,
    question: "Where do I find my Purchase or License code?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vehicula porttitor rutrum. Ut laoreet sodales rutrum. Ut eget tristique nibh. Cras mattis est eget lacus dignissim, non consectetur velit pellentesque. Ut sit amet placerat eros, id gravida arcu. Sed felis purus, tempor ac vestibulum nec, consectetur at purus.",
  },
  {
    id: 3,
    question: "Do I need to buy a licence for each site?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vehicula porttitor rutrum. Ut laoreet sodales rutrum. Ut eget tristique nibh. Cras mattis est eget lacus dignissim, non consectetur velit pellentesque. Ut sit amet placerat eros, id gravida arcu. Sed felis purus, tempor ac vestibulum nec, consectetur at purus.",
  },
  {
    id: 4,
    question: "Is my license transferable?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vehicula porttitor rutrum. Ut laoreet sodales rutrum. Ut eget tristique nibh. Cras mattis est eget lacus dignissim, non consectetur velit pellentesque. Ut sit amet placerat eros, id gravida arcu. Sed felis purus, tempor ac vestibulum nec, consectetur at purus.",
  },
];

export const leavemsfaqsData = [
  {
    id: 1,
    question: "What is a Leave Management System?",
    answer:
      "A Leave Management System is a tool designed to automate and streamline the process of managing employee leaves, including approvals, balances, and policy enforcement.",
  },
  {
    id: 2,
    question: "How does the Leave Management System benefit my organization?",
    answer:
      " It reduces administrative workload, minimizes errors, and ensures compliance with company leave policies, improving overall HR efficiency.",
  },
  {
    id: 3,
    question: "Can the Leave Management System handle multiple leave types?",
    answer:
      "Yes, the system supports various leave types, such as sick leave, vacation, and casual leave, with customizable policies for each.",
  },
  {
    id: 4,
    question: "Is it possible to integrate the Leave Management System with our existing HR software?",
    answer:
      " Absolutely. Our system is designed to integrate seamlessly with your existing HR tools, ensuring a smooth workflow.",
  },
];

export const aboutfaqData = [
  {
    id: 1,
    question: "What services are included in the pricing plans?",
    answer:
      "Each plan offers a range of services tailored to your needs, from basic tools to complete solutions.",
  },
  {
    id: 2,
    question: "Can I switch plans later on?",
    answer:
      "Yes, you can easily adjust your plan anytime, with no extra fees for upgrading or downgrading.",
  },
  {
    id: 3,
    question: "Is there a minimum contract period?",
    answer:
      "No, enjoy flexible plans with no long-term commitments. Choose between monthly or annual billing.",
  },
  {
    id: 4,
    question: "Are there any hidden fees?",
    answer:
      "No hidden costs—our pricing is fully transparent, so you pay exactly what’s outlined in the plan details.",
  },
];

export const faqs3 = [
  {
    question: "Our Story",
    answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
            condimentum, mauris a bibendum cursus, risus quam venenatis ipsum,
            in facilisis tellus lacus ac augue. Fusce placerat condimentum
            luctus. Pellentesque venenatis lacus eu ex sollicitudin maximus.
            Class aptent taciti sociosqu ad litora torquent per conubia nostra,
            per inceptos himenaeos. Vestibulum rhoncus eros vitae enim lobortis
            cursus. Suspendisse potenti. Ut sed tortor ut dolor consequat congue
            a lacinia ante. Phasellus vel urna eu lacus luctus luctus vitae quis`,
  },
  {
    question: "Our Mission",
    answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
            condimentum, mauris a bibendum cursus, risus quam venenatis ipsum,
            in facilisis tellus lacus ac augue. Fusce placerat condimentum
            luctus. Pellentesque venenatis lacus eu ex sollicitudin maximus.
            Class aptent taciti sociosqu ad litora torquent per conubia nostra,
            per inceptos himenaeos. Vestibulum rhoncus eros vitae enim lobortis
            cursus. Suspendisse potenti. Ut sed tortor ut dolor consequat congue
            a lacinia ante. Phasellus vel urna eu lacus luctus luctus vitae quis`,
  },
];
